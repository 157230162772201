import React, {FC} from "react";
import Link from "next/link";
import {rightUrl, url} from "../../../services/utils";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import {useRouter} from "next/router";
import {IAddToCart as IProps} from "./types";
import cls from "./ProductCard.module.scss";

const AddToCart: FC<IProps> = ({
                                   product,
                                   prefetch,
                                   tablet,
                                   route,
                               }) => {
    const router = useRouter();
    return (
        <Link
            href={tablet ? rightUrl(url.product(product), route ? router : null) : url.product(product)}
            prefetch={prefetch}
        >
            <a>
                <button
                    type="button"
                    className={classNames(` btn btn-primary 
                    ${cls[`product-card__addToCart${tablet ? "-tablet" : ""}`]} 
                    ${tablet ? cls["show-for-tablet"] : cls["hide-for-tablet"]}
                 `)}
                >
                    <FormattedMessage
                        id="add_to_cart"
                        defaultMessage="Add to cart"
                    />
                </button>
            </a>

        </Link>
    )
}

export default AddToCart;
