import React, {FC} from "react";
import Link from "next/link";
import {rightUrl, url} from "../../../services/utils";
import {setCrossValid, setTempData} from "../../../store/general";
import {useDispatch} from "react-redux";
import {IProductCardImage as IProps} from "./types";
import cls from "../ProductCard/ProductCard.module.scss"
import classNames from "classnames";

const ProductCardImage: FC<IProps> = ({
                                          product,
                                          router,
                                          openUpCrossProd,
                                          addToast,
                                          tablet
                                      }) => {
    const dispatch = useDispatch();
    return (
        <Link
            href={rightUrl(url.product(product), router)} prefetch={false}
            className={cls["product-image__body"]}
        >
            <a>

                    <div className={`${cls["product-image__body"]} ${cls["product-image__default-fms"]}`}>
                        <div className={`${cls["item_overlay"]} ${cls["hide-for-tablet"]}`} />

                    <div className={cls["img_btn_wrapper"]}>
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch(setTempData([product]))
                                dispatch(setCrossValid(false))
                                openUpCrossProd(product)
                                addToast(product)
                            }}
                            type="button"

                        >
                            Add to cart
                        </button>
                    </div>
                </div>
            </a>
        </Link>
    )
}

export default ProductCardImage;
