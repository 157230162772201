import React, {FC} from "react"
import {useSelector} from "react-redux"
import {IGlobalProduct, IState} from "../../types/state";
import classNames from "classnames";
import {HeartSvg} from "../../svg";
import cls from "./shared.module.scss"

interface IWIshIconProps {
    product: any | React.ReactElement | React.ReactNode
}

const WishIcon: FC<IWIshIconProps> = ({product}) => {
    const wishlist = useSelector((state: IState) => state.wishlist)
    const isProductInWishList = wishlist?.find((item: IGlobalProduct) => item.id === product.id)
    const classes = classNames(cls['wishlist-circle'], {
        [cls['wishlist-circle-clicked']]: isProductInWishList
    })


    return (
        <div className={cls["wishlist-icon-wrapper"]}>
            {/*<WishCircle className={classes}/>*/}
            {
                isProductInWishList
                    ? <HeartSvg className={cls["inner-wishlist"]}/>
                    : <HeartSvg/>
            }
        </div>
    )
}

export default WishIcon